<template>
    <ion-page class="verify">
        <Header :back="false"></Header>
        <ion-content class="ion-padding" fullscreen>
            <ion-card color="light">
                <ion-card-header>
                    <ion-card-title class="ion-text-center">Eingaben überprüfen</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <p class="ion-text-center">Sie haben nochmal die Möglichkeit Ihre Bewertung zu überprüfen.</p>
                </ion-card-content>
            </ion-card>
            <ion-grid>
                <IonRow v-if="!type" class="ion-justify-content-center ion-align-items-center ">
                    <IonCol size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <ion-card-title>Aussehen</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <p>{{ points.points1 }} / 10</p>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                    <IonCol size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <ion-card-title>Teig/Konsistenz</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <p>{{ points.points2 }} / 10</p>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                    <IonCol size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <ion-card-title>Geruch</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <p>{{ points.points3 }} / 10</p>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                    <IonCol size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <ion-card-title>Geschmack</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <p>{{ points.points4 }} / 10</p>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                    <IonCol size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <ion-card-title>Kommentar</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <p>{{ points.comment }}</p>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                </IonRow>
                <IonRow v-else class="ion-justify-content-center ion-align-items-center">

                   <IonCol v-for="(question, index) in questions" :key="index" size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <h6>{{question.title}}</h6>
                            </ion-card-header>
                            <ion-card-content>
                            <p><b>Antwort:</b> {{points["answer"+(index+1)]}}/5</p>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                    <IonCol size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <h6>Das Produkt ist als Innovation erkennbar und verdient diesen "Titel"</h6>
                            </ion-card-header>
                            <ion-card-content>
                                <p><b>Antwort: </b>
                                    <span v-if="points['answer9'] === 0">K.A</span>
                                    <span v-if="points['answer9'] === 1">Nein</span>
                                    <span v-if="points['answer9'] === 3">Eher nicht</span>
                                    <span v-if="points['answer9'] === 5">Ja</span>
                                </p>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                    <IonCol size="12">
                        <ion-card color="light">
                            <ion-card-header>
                                <h6>Kommentar</h6>
                            </ion-card-header>
                            <ion-card-content>
                                <p><b>Antwort:</b> {{points["commentInnovation"]}}</p>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                </IonRow>
                <ion-card color="light">
                    <ion-card-header>
                        <ion-card-title class="ion-text-center">Eingaben überprüfen</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-button class="ion-margin" expand="block" @click="back">Erneut bearbeiten</ion-button>
                        <ion-button class="ion-margin" color="success" expand="block" @click="save">Absenden
                        </ion-button>
                    </ion-card-content>
                </ion-card>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonIcon,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonPage,
    modalController
} from '@ionic/vue';
import Header from "@/components/Header";
import {ResultPoints} from "@/util/ResultService";
import {defineComponent} from 'vue';
export default defineComponent({
    name: "VerifyRating",
    components: {
        IonContent,
        Header,
        IonGrid,
        IonRow,
        IonCol,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardTitle,
        IonButton,
        IonPage
    },
    props: {
        points: {
            type: ResultPoints,
            required: true
        },
        tableId: {
            type: String,
            required: true
        },
        origin: {
            type: String,
            required: false
        },
        type: {
            type: Boolean,
            required: true
        },
        questions: {
            type: Array,
            required: false
        },
    },
    methods: {
        back() {
            modalController.dismiss()
        },
        save() {
            this.points.finalize().then(() => {
                if (this.origin) {
                    this.$router.replace('/openratings')
                } else {
                    this.$router.replace({name: 'CheeseList', params: {tableID: this.tableId}})
                }
                modalController.dismiss()
            });
        }
    }
})
</script>
